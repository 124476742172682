import React, {useContext, useEffect, useState} from "react";
import {
	Backdrop,
	Button, CircularProgress,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle, Grid, TextField
} from "@material-ui/core";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import ActionContext from "../../context/action.context";
import {makeStyles} from "@material-ui/core/styles";
import firebaseConnector from "../../api/firebase-connector";

export default function DialogAtelier({atelier, categories, isOpen, handleClose}){

	const [exercicesList, setExercicesList] = useState([])

	useEffect(()=>{
		console.log("atelier", atelier.id);

		firebaseConnector.firestore().collection("Ateliers").doc("" + atelier.id).collection("exercices").orderBy("id", 'asc').onSnapshot((snapshot => {
			//console.log(snapshot.docs);
			setExercicesList(snapshot.docs.map(doc=>doc.data()))
		}))

	}, [atelier])

	useEffect(()=>{

	}, [isOpen])

	const useStyles = makeStyles((theme) => ({
		titleDialog : {
			fontWeight: 900,
			color : theme.palette.primary.main,
			margin : "0px"
		},
		subtitleDialog : {
			fontWeight: 800,
			color : theme.palette.secondary.main,
			fontSize : '75%',
			margin : "0px"
		},
		titleExercice : {
			fontWeight : 600,
			color : theme.palette.text.main,
			wordBreak : "break-word"
		},
		exercicesTitle : {
			fontWeight: 800,
			color : theme.palette.text.main,
			fontSize : '100%',
			margin : "0px",
			wordBreak : "break-word"
		},
		exercicesDescription : {
			fontWeight: 400,
			color : theme.palette.text.main,
			fontSize : '75%',
			margin : "0px"
		},
		timeline : {
			"& .MuiTimelineItem-root" : {
				minHeight : "55px"
			},
			"& .MuiTimelineConnector-root" : {
				backgroundColor : theme.palette.secondary.light
			},
			[theme.breakpoints.down("xs")] : {

			}
		},
		dialogContent : {
			paddingLeft : "4px",
			paddingRight : "4px"
		},
		dialogContentHeader : {
			paddingLeft : "20px",
			paddingRight : "20px"
		}
	}));
	const classes = useStyles();

	if(!atelier){
		atelier = {
			name : "",
			category : 1
		}
	}

	return(
		<Dialog
			fullWidth={true}
			maxWidth={"md"}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				<h6 className={classes.subtitleDialog}>{categories  && categories[atelier.category - 1] ? categories[atelier.category - 1].name : ""}</h6>
				<h4 className={classes.titleDialog}>Atelier {atelier.subId} : {atelier.name}</h4>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<div className={classes.dialogContentHeader}>
					<h6 className={classes.titleExercice}>Description</h6>
					<p>{atelier.description}</p>
					<h6 className={classes.titleExercice}>Les exercices</h6>
				</div>
				<Timeline align="alternate" className={classes.timeline}>
					{(exercicesList && exercicesList.length > 0) && exercicesList.map((exercice, key) => (
						<TimelineItem key={key}>
							<TimelineSeparator>
								<TimelineDot color="secondary"/>
								{exercicesList.length - 1 !== key && <TimelineConnector />}
							</TimelineSeparator>
							<TimelineContent>
								<h6 className={classes.exercicesTitle}>{exercice.name}</h6>
								<p className={classes.exercicesDescription}>{exercice.description}</p>
							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" variant="contained">
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}