import React, {useContext, useState} from "react";
import {Grid, Hidden, IconButton, Tooltip} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {makeStyles, useTheme} from "@material-ui/core/styles";

import DialogUsager from "./DialogUsager";
import Alert from "../../utils/Alert";

import ActionContext from "../../context/action.context";

import {
	CheckRounded,
	CreateRounded,
	DeleteRounded, FilterNoneRounded, VisibilityRounded,
} from "@material-ui/icons";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import firebaseConnector from "../../api/firebase-connector";

export default function UsagerRow({usager}){

	let userId = firebaseConnector.auth().currentUser.uid;

	const [copyIcon, setCopyIcon] = useState(<FilterNoneRounded/>);

	var history = useHistory();

	const theme = useTheme()
	const {deleteUsager} = useContext(ActionContext)

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const handleDelete = () => {
		Alert.warningDelete().then(()=>{deleteUsager(usager)})
	}

	const useStyles = makeStyles(() => ({
		tableRow: {
			"& td" : {
				paddingTop: "0.25rem",
				paddingBottom: "0.25rem",
			}
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			wordBreak : "break-word"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center",
			wordBreak : "break-word"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		},
		row : {
			background : "transparent",
			borderBottom : theme.palette.primary.light + " 1px solid",
		}

	}))
	const classes = useStyles();

	function handleCopyLink() {
		var old = copyIcon
		setCopyIcon(<CheckRounded/>);
		setTimeout(() => {
			setCopyIcon(old);
		}, 1500);
	}

	return(
		<Grid item xs={12} className={classes.row}>
			<Grid container style={{padding : "15px"}}>
				<Grid item xs={3} md={3} className={classes.rowContainer}>
					<p className={classes.rowText}>
						{usager.nom}
					</p>
				</Grid>
				<Grid item xs={3} md={3} className={classes.rowContainer}>
					<p className={classes.rowText}>
						{usager.prenom}
					</p>
				</Grid>
				<Grid item xs={6} md={6} className={classes.rowContainer} style={{textAlign: "center"}}>
					<IconButton
						onClick={()=>{history.push("./usagers/" + usager.id)}}
						aria-label="edit"
						style={{
							backgroundColor: theme.palette.primary.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Voir le détail">
							<VisibilityRounded/>
						</Tooltip>
					</IconButton>
					<Hidden smDown>
						<IconButton
							aria-label="edit"
							style={{
								backgroundColor: theme.palette.primary.main,
								color: "white",
								marginLeft: "1rem"
							}}
							className="ml-4"
						>
							<CopyToClipboard onCopy={handleCopyLink} text={"https://ateliernum.fr/home/" + userId + "/" + usager.id}>
								<Tooltip title="Copier le lien de l'usager">
									{copyIcon}
								</Tooltip>
							</CopyToClipboard>
						</IconButton>
						<IconButton
							onClick={()=>{setModalIsOpen(true)}}
							aria-label="edit"
							style={{
								backgroundColor: theme.palette.primary.main,
								color: "white",
								marginLeft: "1rem"
							}}
							className="ml-4"
						>
							<Tooltip title="Editer">
								<CreateRounded/>
							</Tooltip>
						</IconButton>
					</Hidden>
					<IconButton
						onClick={handleDelete}
						aria-label="delete"
						style={{
							backgroundColor: theme.palette.danger.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Supprimer">
							<DeleteRounded />
						</Tooltip>
					</IconButton>
				</Grid>
			</Grid>
			<DialogUsager
				handleClose={()=>{setModalIsOpen(false)}}
				isEdit={true}
				isOpen={modalIsOpen}
				usager={usager}
			/>
		</Grid>
	)
}