import React, {useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Fab} from "@material-ui/core";
import firebaseConnector from "../api/firebase-connector";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const TutorialDialog = ({id, title, subtitle, open : openProp = false, withoutButton = false, children}) => {

    const [open, setOpen] = useState(openProp);
    const [firstOpen, setFirstOpen] = useState(false)

    const useStyles = makeStyles((theme) => ({
        dialogTitle : {
            color : theme.palette.primary.main,
            fontWeight : 800,
            margin : 0
        },
        dialogSubtitle : {
            color : theme.palette.secondary.main,
            fontWeight : 600,
            fontSize : "75%",
            margin : 0
        },
    }));
    const classes = useStyles();

    let userId = firebaseConnector.auth().currentUser.uid;

    useEffect(() => {
        const prepare = async () => {
            var doc = await firebaseConnector.firestore().collection("Tutorials").doc(userId).get();
            const boolOpen = doc.exists ? (doc.data()[id] !== 1) : true;
            setOpen(boolOpen)
            setFirstOpen(boolOpen)
        }
        prepare()

    }, [id])

    useEffect(() => {
        setOpen(openProp)
    }, [openProp, withoutButton])

    const handleClose = () => {

        if(firstOpen){
            firebaseConnector.firestore().collection("Tutorials").doc("" + userId).set({
                [id] : 1
            }, {merge : true});
        }

        setOpen(false);
    }

    return (
        <>
            {
                !withoutButton && (
                    <Fab color="primary" aria-label="add" style={{position : "fixed", bottom : "15px", right : "15px", cursor : "pointer"}} onClick={() => {
                        setOpen(true);
                    }}>
                        <span style={{fontSize : "200%"}}>?</span>
                    </Fab>
                )
            }
            <Dialog open={open}>
                <DialogTitle>
                    <h4 className={classes.dialogTitle}>{title ?? "Tutoriel"}</h4>
                    <h5 className={classes.dialogSubtitle}>{subtitle ?? ""}</h5>
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button
                        primary
                        onClick={handleClose}
                    >
                        J'ai tout compris !
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TutorialDialog;
