import React, {useContext, useState} from "react";
import {Grid, Hidden, IconButton, Tooltip} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {makeStyles, useTheme} from "@material-ui/core/styles";

import DialogAtelier from "./DialogAtelier";
import Alert from "../../utils/Alert";

import ActionContext from "../../context/action.context";

import {
	CreateRounded,
	DeleteRounded, PlayArrow, PlayArrowRounded, SendRounded, VisibilityRounded,
} from "@material-ui/icons";

export default function AtelierRow({atelier, categories}){

	var history = useHistory();

	const theme = useTheme()

	const [modalDetailIsOpen, setModalDetailIsOpen] = useState(false)
	const [modalEnvoiIsOpen, setModalEnvoiIsOpen] = useState(false)

	const useStyles = makeStyles(() => ({
		tableRow: {
			"& td" : {
				paddingTop: "0.25rem",
				paddingBottom: "0.25rem",
			}
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			wordBreak : "break-word"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center",
			wordBreak : "break-word"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		},
		row : {
			background : "transparent",
			borderBottom : theme.palette.primary.light + " 1px solid",
		}

	}))
	const classes = useStyles();

	return(
		<Grid item xs={12} className={classes.row}>
			<Grid container style={{padding : "15px"}}>
				<Grid item xs={4} md={4} className={classes.rowContainer}>
					<p className={classes.rowText}>
						{atelier.name}
					</p>
				</Grid>
				<Grid item xs={4} md={4} className={classes.rowContainer}>
					<p className={classes.rowText}>
						{categories[atelier.category - 1] ? categories[atelier.category - 1].name : ""}
					</p>
				</Grid>
				<Grid item xs={4} md={4} className={classes.rowContainer} style={{textAlign: "center"}}>
					<IconButton
						onClick={()=>{setModalDetailIsOpen(true)}}
						aria-label="edit"
						style={{
							backgroundColor: theme.palette.primary.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Voir le détail">
							<VisibilityRounded/>
						</Tooltip>
					</IconButton>
					<IconButton
						onClick={() => {window.open("https://ateliernum.fr/Workshop/" + atelier.id + "/Exercice/1", "_blank")}}
						aria-label="delete"
						style={{
							backgroundColor: theme.palette.primary.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Essayer l'atelier">
							<PlayArrowRounded />
						</Tooltip>
					</IconButton>
				</Grid>
			</Grid>
			<DialogAtelier
				handleClose={()=>{setModalDetailIsOpen(false)}}
				isEdit={true}
				isOpen={modalDetailIsOpen}
				atelier={atelier}
				categories={categories}
			/>
		</Grid>
	)
}