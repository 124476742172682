import React, {useContext, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import firebaseConnector from "../api/firebase-connector";
import StringUtils from "../utils/StringUtils";
import ToastContext from "../context/toast.context";
import Alert from "../utils/Alert";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";

import {useHistory} from "react-router-dom";
import {Tooltip} from "@material-ui/core";

export default function AccountDialog({open, handleClose}) {
    const [email, setEmail] = useState("")

    const {toast} = useContext(ToastContext);
    const history = useHistory();

    useEffect(()=>{
        const user = firebaseConnector.auth().currentUser;
        if(user !== null){
            setEmail(user.email)
        }
    }, [open])

    const handleSubmit = () => {
        let promisesArray = []
        let user = firebaseConnector.auth().currentUser;

        if(email !== firebaseConnector.auth().currentUser.email){
            promisesArray.push(user.updateEmail(email))
        }

        Promise.all(promisesArray).then(()=>{
            toast.fire({icon: "success", title: "Compte modifiée"})
            handleClose()
        }).catch(Alert.error)
    }

    const isValid = () => {
        return email !== "" && StringUtils.isEmail(email)
    }

    const handleResetPassword = () => {
        handleClose()
        firebaseConnector.auth().sendPasswordResetEmail(firebaseConnector.auth().currentUser.email)
            .then(()=>{
                toast.fire({icon: "success", title: "Un mail vous a été envoyé"}).then(()=>{
                    firebaseConnector.auth().signOut().then(()=>{
                        history.push('/login')
                    })
                })
            })
            .catch(Alert.error)
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Éditer mon compte</DialogTitle>
            <DialogContent>
                <MDBRow center={true}>
                    <MDBCol size="8" className="text-center">
                        <Tooltip title={"Pour changer d'adresse email, contactez nous"}><p>{email}</p></Tooltip>
                        <Button onClick={handleResetPassword} color="primary" variant="contained" className="mb-3">
                            <MDBIcon icon="sync" className="mr-2"/>
                            Réinitialiser mon mot de passe
                        </Button>
                    </MDBCol>
                </MDBRow>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}