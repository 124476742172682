import React, {useContext, useEffect, useState} from "react";
import {
	Backdrop,
	Button, CircularProgress,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle, Grid, TextField
} from "@material-ui/core";

import ActionContext from "../../context/action.context";
import {makeStyles} from "@material-ui/core/styles";

export default function DialogUsager({usager : usagerProp, isEdit, isOpen, handleClose}){

	const [usager, setUsager] = useState(usagerProp ? usagerProp : {
		nom : "",
		prenom : ""
	})

	const [isValid, setIsValid] = useState(false)

	const [isPending, setIsPending] = useState(false)

	const {addUsager, editUsager} = useContext(ActionContext)

	useEffect(()=>{

		let valid = usager.nom !== "" && usager.prenom !== "";
		setIsValid(valid)

	}, [usager])

	useEffect(()=>{

		if(isEdit && usagerProp){
			setUsager(usagerProp)
		}

		let valid = usager.nom !== "" && usager.prenom !== "";
		setIsValid(valid)

	}, [isOpen, isEdit, usagerProp])

	const handleSubmit = async () => {

		setIsPending(true)

		if(isEdit){
			await editUsager(usager)
		}else{
			await addUsager(usager);
		}

		setIsPending(false)
		handleClose()

		setUsager({
			nom : "",
			prenom : ""
		})

	}

	const useStyles = makeStyles((theme) => ({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: theme.palette.primary.main,
		}
	}));
	const classes = useStyles();


	return(
		<Dialog
			fullWidth={true}
			maxWidth={"md"}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<Backdrop className={classes.backdrop} open={isPending}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<DialogTitle id="form-dialog-title">
				{isEdit ? "Modifier" : "Ajouter"} un usager
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12} md={6}>
						<TextField
							onChange={(event) => {setUsager({...usager, nom : event.target.value})}}
							value={usager.nom}
							required
							margin="dense"
							label="Nom de l'usager"
							type="text"
							style={{width : "80%", marginLeft : "10%"}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							onChange={(event) => {setUsager({...usager, prenom : event.target.value})}}
							value={usager.prenom}
							required
							margin="dense"
							label="Prénom de l'usager"
							type="text"
							style={{width : "80%", marginLeft : "10%"}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!isValid}>
					{isEdit ? "Modifier" : "Ajouter"}
				</Button>
			</DialogActions>
		</Dialog>
	)
}