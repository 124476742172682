import React, {useContext, useEffect, useState} from 'react'
import {MDBCol, MDBIcon, MDBInput, MDBRow} from "mdbreact";

import {useHistory} from "react-router-dom";
import {Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import firebaseConnector from "../api/firebase-connector";

import ConfigContext from "../context/config.context";
import Hidden from "@material-ui/core/Hidden";


export default function LoginPage(){
    const {config} = useContext(ConfigContext)
    const [images, setImages] = useState(["", "", ""])

    useEffect(() => {
        const prepare = async () => {
            setImages([config.loginLeftPanelImagePath, config.loginRightPanelImagePath, config.logoImagePath]);
        }
        prepare()
    }, [])

    useEffect(() => {

    }, [images])

    const useStyles = makeStyles((theme) => ({
        root: {
            margin: 0,
            height: "100vh",
            justifyContent: "center",
            overflow : "hidden"
        },
        leftPanel: {
            backgroundImage: `url(${images[0]})`,
            backgroundPosition : "center",
            backgroundSize: "cover",
            backgroundRepeat : "no-repeat",
            color : theme.palette.text.main,
            display : "flex",
            flexDirection : "column",
            justifyContent : "center",
            alignItems : "center",
            height : "100%",
            overflowY : "auto",
            [theme.breakpoints.only("sm")] : {
                justifyContent : "flex-start"
            },
        },
        leftPanelImage : {
            [theme.breakpoints.up("lg")] : {
                paddingBottom: "3rem"
            },
            [theme.breakpoints.down("xs")] : {
                paddingBottom: "3rem"
            },
            [theme.breakpoints.up("xs")] : {
                paddingTop: "3rem"
            },
            [theme.breakpoints.down("lg")] : {
                paddingTop: "3rem"
            }
        },
        rightPanel: {
            backgroundImage: `url(${images[1]})`,
            backgroundPosition : "center",
            backgroundSize : "contain",
            backgroundRepeat : "no-repeat",
            display: "grid",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "#FFF",
            color : theme.palette.primary.main,
        },
        headerText:{
            fontWeight : "bold",
            width : "100%",
            textAlign : "center",
            fontSize : "3.5rem",
            color: theme.palette.text.light,
            [theme.breakpoints.only("sm")] : {
                paddingTop: "3rem",
                paddingBottom: "3rem"
            },
        },
    }));
    const classes = useStyles()

    return(
        <Grid container className={classes.root}>
            <Grid item xs={12} md={6} className={classes.leftPanel}>
                <Hidden mdUp>
                    <p className={classes.headerText}>
                        {config.title}
                    </p>
                </Hidden>
                <LoginForm/>
            </Grid>
            <Grid item xs={0} md={6} className={classes.rightPanel}/>
        </Grid>
    );
}


const LoginForm = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const [isValidate, setIsValidate] = useState(false)

    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        inputContainer:{
            fontWeight: "normal",
            fontSize: "1rem",
            color: "inherit",
        },
        loginButton: {
            borderRadius : "25px",
            backgroundColor: theme.palette.text.lighter,
            color: theme.palette.primary.main,
            "&.disabled":{
                backgroundColor: "rgba(0,0,0,0.118)"
            }
        },
        loginClear : {
            width : "90%",
            marginLeft : "5%",
            color : theme.palette.text.lighter,
            "& .md-form label" : {
                color: theme.palette.text.lighter + " !important",
                "& .active" : {
                    color: theme.palette.text.lighter + " !important",
                }
            },
            '& .md-form input':{
                color: theme.palette.text.lighter,
                borderBottom: `1px solid ${theme.palette.text.lighter} !important`,
                "&:focus" : {
                    boxShadow : "0 1px 0 0 " + theme.palette.text.lighter + " !important"
                }
            },
            '& .md-form i':{
                color: theme.palette.text.lighter + " !important",
                "& .active" : {
                    color: theme.palette.text.lighter + " !important",
                }
            },
        }
    }));
    const classes = useStyles()

    const {config} = useContext(ConfigContext)

    useEffect(()=>{
        setIsValidate(email !== "" && password !=="")
    }, [email, password])

    const handleSubmit = async (event) =>{
        event.preventDefault()
        firebaseConnector.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                history.push("/dashboard");
            })
            .catch(error => {
                setError(error)
            });
    }

    return(
        <form onSubmit={handleSubmit} style={{textAlign: "left", minWidth : "75%"}}>
            <div className={classes.loginClear}>
                <h2 style={{textAlign: "center", fontWeight: "bold", color: "inherit"}}>Connexion</h2>
                <br/>

                <MDBInput
                    containerClass={classes.inputContainer}
                    focused
                    label="Adresse e-mail"
                    getValue={setEmail}
                    icon="envelope"
                    value={email}
                    iconLight={true}
                    type="email"
                />

                <MDBInput
                    containerClass={classes.inputContainer}
                    value={password}
                    getValue={setPassword}
                    label="Mot de passe"
                    icon="lock"
                    iconLight={true}
                    type="password"
                />

                <div className="text-center" style={{marginTop: "2rem"}}>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={!isValidate}
                        className={classes.loginButton}
                        size="large"
                    >
                        Connexion
                    </Button>
                    {error && <p className="mt-1">{error.message}</p>}
                    <p>
                        <br/>
                        <a href="/reset-password" style={{textDecoration : "none", color : "inherit"}}>
                            Mot de passe oublié ? <span style={{textDecoration : "underline", fontWeight : "bold"}}>Cliquez ici</span>
                        </a>
                        <br/>
                        <a href="/signup" style={{textDecoration : "none", color : "inherit"}}>
                            Vous n'avez pas de compte ? <span style={{textDecoration : "underline", fontWeight : "bold"}}>Créez en un ici</span>
                        </a>
                    </p>
                </div>
            </div>
        </form>
    );
}