import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import DashboardUsagers from "../section/usagers.dashboard";
import DashboardAteliers from "../section/ateliers.dashboard";
import DashboardDetailUsagers from "../section/detail.usagers.dashboard";

const RoutesApp = () => {
    return (
        <Switch>
            <Route path='/' exact component={()=>(<Redirect to="/dashboard/ateliers"/>)}/>
            <Route path='/dashboard' exact component={()=>(<Redirect to="/dashboard/ateliers"/>)}/>

            <Route path="/dashboard/ateliers" exact component={DashboardAteliers}/>

            <Route path="/dashboard/usagers" exact component={DashboardUsagers}/>
            <Route path="/dashboard/usagers/:idUsager" exact component={DashboardDetailUsagers}/>

            <Route path="*">
                <Redirect to="/"/>
            </Route>
        </Switch>
    );
};

export default RoutesApp;
