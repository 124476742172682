import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import firebaseConnector from "../api/firebase-connector";

export default function AuthRoute({component : Component, type, ...rest}) {
    return (
        <Route {...rest} render={(props) => (
            firebaseConnector.auth().currentUser !== null
              ? <Component {...props} />
              : <Redirect to={{
                  pathname: '/login',
                  state: { from: props.location }
                }} />
          )} />
    )
}