import React, {useContext, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import {AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import RoutesApp from "../routes/RoutesApp";

import SideBarLink from "../components/SideBarLink";
import {MDBIcon} from "mdbreact";
import firebaseConnector from "../api/firebase-connector";
import {AccountCircle} from "@material-ui/icons";
import AccountDialog from "../components/AccountDialog";
import ConfigContext from "../context/config.context";

import logo from "../assets/img/logo-visite-plus.png"

const routes_config = require('../routes/route.config.json');

export default function RoutesWithNavigationApp(){
    const drawerWidthXs = "80%";
    const drawerWidthSm = "80%";
    const drawerWidthMd = "20%";
    const drawerWidthLg = "18%";
    const drawerWidthXl = "15%";

    const theme = useTheme();
    const {config} = useContext(ConfigContext)

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        appBar: {
            backgroundColor: "#ffffff",
            color: "#444448",
            right: "inherit",
            zIndex: 1050,
            boxShadow: "none",
            [theme.breakpoints.down('sm')]: {
                boxShadow : "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
                backgroundColor: "#444448",
                color: theme.palette.primary.main,
                width : "100vw"
            },
            [theme.breakpoints.up('md')]: {
                marginLeft : drawerWidthMd,
                marginRight : drawerWidthMd,
                width : "calc(100vw - " + drawerWidthMd + ")"
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft : drawerWidthLg,
                marginRight : drawerWidthLg,
                width : "calc(100vw - " + drawerWidthLg + ")"
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft : drawerWidthXl,
                marginRight : drawerWidthXl,
                width : "calc(100vw - " + drawerWidthXl + ")"
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // drawer
        drawer: {
            backgroundColor: "white",
            [theme.breakpoints.down('xs')]: {
                width: 0
            },
            [theme.breakpoints.up('sm')]: {
                width: 0
            },
            [theme.breakpoints.up('md')]: {
                flexShrink: 0,
                width: drawerWidthMd
            },
            [theme.breakpoints.up('lg')]: {
                flexShrink: 0,
                width: drawerWidthLg
            },
            [theme.breakpoints.up('xl')]: {
                flexShrink: 0,
                width: drawerWidthXl
            },
            height : "100%"
        },
        drawerRoot : {
            height : "100%"
        },
        drawerHeader: {
            marginTop: "2rem",
            marginBottom: "2rem",
            display: "inline-flex",
            alignItems: "center"
        },
        drawerTitle:{
            color: theme.palette.primary.main,
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "400",
            marginBottom: "initial",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            [theme.breakpoints.only('md')]: {
                fontSize : "150%"
            },
        },
        drawerPaper: {
            backgroundColor: "white",
            [theme.breakpoints.down('xs')]: {
                width: drawerWidthXs
            },
            [theme.breakpoints.up('sm')]: {
                width: drawerWidthSm
            },
            [theme.breakpoints.up('md')]: {
                width: drawerWidthMd
            },
            [theme.breakpoints.up('lg')]: {
                width: drawerWidthLg
            },
            [theme.breakpoints.up('xl')]: {
                width: drawerWidthXl
            },
        },
        drawerListText : {
            [theme.breakpoints.up('md')]: {
                fontSize: "1.2rem",
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "0.8rem",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.2rem",
            },
        },
        drawerImg: {
            [theme.breakpoints.up('md')]: {
                width: "10vw",
            },
            [theme.breakpoints.down('md')]: {
                width: "35vw",
            },
        },
        drawerButton:{
            backgroundColor: "transparent",
            border: "none",
            textDecoration: "underline",
            fontSize: "1.1rem",
            marginTop: "1.5rem",
            fontWeight: "400",
            color: theme.palette.text.light
        },
        // drawer content
        listItemIcon:{
            minWidth: "45px",
            color: theme.palette.text.light
        },
        logoutLink:{
            position: "relative",
            fontSize: "1.2rem",
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                padding: "0px",
            },
            marginRight: 0,
            marginTop: "80px",
            marginBottom: 0
        },
        disconnectButton : {
            position : "absolute",
            bottom : "15px",
            left : "25px"
        }
    }));

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isDialogAccountOpen, setIsDialogAccountOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        firebaseConnector.auth().signOut()
    }

    const drawer = (
        <div style={{textAlign: "center"}} className={classes.drawerRoot}>
            <div className={classes.drawerHeader}>
                <h2 className={classes.drawerTitle}>
                    {config.title}
                </h2>
            </div>
            <List>
                {routes_config.map((route, index)=>(
                    <SideBarLink
                        key={index}
                        to={route.to}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <MDBIcon icon={route.icon} light={true} size="2x" className="mr-4"/>
                        </ListItemIcon>
                        <ListItemText primary={route.text} className={classes.drawerListText} disableTypography={true}/>
                    </SideBarLink>
                ))}
            </List>
            <div className={classes.disconnectButton}>
                <button
                    className={`mt-3 ${classes.drawerButton}`}
                    onClick={handleLogout}
                >
                    <MDBIcon far icon="sign-in-alt float-left  pr-3 mt-1 font-weight-lighter" />
                    Déconnexion
                </button>
            </div>
        </div>
    );

    const container = window.document.body

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar}>
                <ToolbarSection
                    handleDrawerToggle={handleDrawerToggle}
                    handleOpenDialog={()=>{setIsDialogAccountOpen(true)}}
                />
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper, }}
                        ModalProps={{ keepMounted: true, }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{ paper: classes.drawerPaper, }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <RoutesApp/>
                <AccountDialog
                    open={isDialogAccountOpen}
                    handleClose={()=>{setIsDialogAccountOpen(false)}}
                />
            </main>
        </div>
    )
}

const ToolbarSection = ({handleDrawerToggle, handleOpenDialog}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const useStyles = makeStyles((theme) => ({
        toolbar:{
            backgroundColor: "#FBFBFB",
            display : "flex",
            justifyContent : "space-around",
            alignItems : "flex-end",
            [theme.breakpoints.up('md')]: {
                paddingTop : "15px",
                paddingBottom : "30px",
            },
            [theme.breakpoints.down('sm')]: {
                alignItems : "center",
            },
        },
        toolbarTitle:{
            flexGrow: "1",
            fontFamily: "'Montserrat', sans-serif",
            color: theme.palette.text.main,
            fontSize: "20pt",
            fontWeight: "bold",
            [theme.breakpoints.up('md')]: {
                marginTop: "1rem",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.2rem"
            },
        },
        accountButton : {
            backgroundColor: "transparent",
            color: "black",
            borderRadius : "25px",
            [theme.breakpoints.up('md')]: {
                marginTop : "15px",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.2rem"
            },
            "& .MuiButton-label .MuiButton-startIcon" : {
                margin : "0px"
            },
            "&:hover" : {
                backgroundColor: theme.palette.primary.main,
                color: "white",
            }
        },
        accountButtonText : {
            [theme.breakpoints.down('sm')]: {
                display : "none",
            }
        },
        drawerToggleButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }));

    const classes = useStyles();

    let history = useHistory();
    let location = useLocation();

    const goBack = () => {
        history.goBack();
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <Toolbar className={classes.toolbar}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.drawerToggleButton}
            >
                <MenuIcon/>
            </IconButton>

            <Typography variant="h4" className={classes.toolbarTitle} noWrap>
                {routes_config.find(route=>route.to === location.pathname) ? routes_config.find(route=>route.to === location.pathname).title : ""}
            </Typography>

            {location.pathname.includes("view") &&
                <Button className="ml-3" size="large" onClick={goBack} color="primary">
                    <ArrowBackIcon className="mr-2"/>
                    <span className="pr-2">Retour</span>
                </Button>
            }

            <Button
                variant="contained"
                color="secondary"
                className={classes.accountButton}
                startIcon={<AccountCircle fontSize={"large"} className={classes.accountButtonIcon}/>}
                onClick={handleMenu}
            >
                <span className={classes.accountButtonText}>
                    {firebaseConnector.auth().currentUser.displayName === null ? "Mon compte" : firebaseConnector.auth().currentUser.displayName}
                </span>
            </Button>

            <TopBarAccountMenu
                handleClose={handleClose}
                anchorEl={anchorEl}
                open={open}
                handleOpenDialog={()=>{handleClose(); handleOpenDialog()}}
            />

        </Toolbar>
    )
}

const TopBarAccountMenu = ({anchorEl, handleClose, open, handleOpenDialog}) => {
    return(
        <Menu
            getContentAnchorEl={null}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={handleOpenDialog}>Modifier mon profil</MenuItem>
        </Menu>
    )
}