import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import ToastContext from "./context/toast.context";
import Swal from 'sweetalert2';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'

import './index.css';

import loginRightPanelImage from './assets/img/loginRightPanelImage.png'
import loginLeftPanelImage from './assets/img/loginLeftPanelImage.png'
import logoImage from './assets/img/logo.png'

import App from './App';
import firebaseConnector from "./api/firebase-connector";
import ConfigContext from "./context/config.context";

const theme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "#FFF",
            main: "#19355C",
            light: "#d1dded",
            background : "white"
        },
        text: {
            main: "#19355C",
            light: "grey",
            lighter : "white"
        },
        secondary: {
            main: "#776977",
            light: "#ebe6eb",
        },
        danger: {
            main: "#D13737"
        },
    },
})
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toastLocal) => {
        toastLocal.addEventListener('mouseenter', Swal.stopTimer)
        toastLocal.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


let config = {
    title: "AtelierNum'",
    loginLeftPanelImagePath: loginLeftPanelImage,
    loginRightPanelImagePath: loginRightPanelImage,
    logoImagePath: logoImage,
}

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ConfigContext.Provider value={{config: config}}>
                <ToastContext.Provider value={{toast: toast}}>
                    <App/>
                </ToastContext.Provider>
            </ConfigContext.Provider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);