import React, {useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

import { makeStyles } from '@material-ui/core/styles';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button, ClickAwayListener,
	Grid, IconButton,
	InputAdornment,
	TextField, Tooltip, withStyles
} from "@material-ui/core";

import firebaseConnector from "../api/firebase-connector"

import ActionContext from '../context/action.context'

import UsagerRow from "../components/Usagers/UsagerRow";
import DialogUsager from "../components/Usagers/DialogUsager";
import {
	CreateRounded, DeleteRounded,
	ExpandMoreRounded,
	FilterNoneRounded,
	SearchRounded,
	VisibilityRounded
} from "@material-ui/icons";
import StringUtils from "../utils/StringUtils";
import ConfigContext from "../context/config.context";
import {MDBInput} from "mdbreact";
import TutorialDialog from "../components/TutorialDialog";

export default function DashboardDetailUsagers() {

	const [copyButtonText, setCopyButtonText] = useState("Copier le lien de l'usager");
	const [link, setLink] = useState("");

	const [usager, setUsager] = useState({
		name : "Nom",
		prenom : "Prénom",
		id : ""
	})
	const [categories, setCategories] = useState([])
	const [ateliers, setAteliers] = useState([])

	let { idUsager } = useParams();

	let userId = firebaseConnector.auth().currentUser.uid;

	const useStyles = makeStyles((theme) => ({
		titleName: {
			fontWeight : "600",
			color : theme.palette.text.main
		},
		headerGrid : {
			paddingBottom : "15px",
			textAlign : "right",
			"& h3" : {
				textAlign : "left"
			},
			[theme.breakpoints.down("sm")] : {
				textAlign : "center",
				"& h3" : {
					textAlign : "center"
				},
			}
		},
		accordionContainer : {
			"& .MuiAccordion-root" : {
				backgroundColor : theme.palette.primary.main,
				color : theme.palette.text.lighter,
				fontSize : "125%",
				fontWeight : "600",
				"& .MuiIconButton-root" : {
					color : theme.palette.text.lighter,
				}
			},
		},
		block : {
			width : "75px",
			height : "30px",
			textAlign : "center",
			margin : "5px",
			borderRadius : "5px",
			backgroundColor : theme.palette.secondary.main,
			color : theme.palette.text.lighter,
			fontWeight : "700",
			cursor : "pointer"
		}
	}));

	const classes = useStyles();

	useEffect(()=>{
		firebaseConnector.firestore().collection("Users_Data").doc(userId).collection("Usagers").doc("" + idUsager).onSnapshot((doc)=>{
			setUsager(doc.data())

			setLink("https://ateliernum.fr/home/" + userId + "/" + doc.id)
		})
		firebaseConnector.firestore().collection("Ateliers_Category").get().then(collection => {
			setCategories(collection.docs.map(doc => doc.data()))
		})
		firebaseConnector.firestore().collection("Ateliers").get().then(collection => {

			Promise.all(collection.docs.map(async (doc) => {
				doc = doc.data()

				let col = await firebaseConnector.firestore().collection("Ateliers").doc("" + doc.id).collection("exercices").get()

				doc.exercices = col.docs.map((exercice) => (exercice.data()))

				return doc;
			})).then((docs) => {
				setAteliers(docs)
			})
		})
	}, [])

	function handleCopyLink() {
		var old = copyButtonText
		setCopyButtonText("Copié !");
		setTimeout(() => {
			setCopyButtonText(old);
		}, 1500);
	}


	return (
		<>
			<Grid container>
				<Grid item xs={12} md={6} className={classes.headerGrid}>
					<h3 className={classes.titleName}>{usager.prenom} {usager.nom}</h3>
				</Grid>
				<Grid item xs={12} md={6} className={classes.headerGrid}>
					<Button
						color="primary"
						variant="contained"
						size="large"
						style={{borderRadius : "25px"}}
					>
						<CopyToClipboard onCopy={handleCopyLink} text={link}>
							<span>{copyButtonText}</span>
						</CopyToClipboard>
					</Button>
				</Grid>
				<Grid item xs={12} className={classes.accordionContainer}>
					{
						categories.map((category, key) => (
							<AccordionCategory
								usager={usager}
								category={category}
								ateliers={ateliers.filter((atelier) => (atelier.category === category.id))}
							/>
						))
					}
				</Grid>
			</Grid>

			<TutorialDialog
				id={"detail.usagers.dashboard"}
				subtitle={"La progression de l'apprenant"}
			>
				<p>
					Vous trouverez sur cette page la liste de tous les ateliers.<br/>
					Vous pouvez ouvrir chaque atelier en cliquant dessus. Cela affichera la progression de l'usager sur cet atelier. Chaque exercice est représenté par une case qui change de couleur selon la progressions.<br/>
				</p>

				<Grid container>
					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<div className={classes.block}>
							1
						</div>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Une case grise signifie que l'exercice n'a pas été terminé.</p>
					</Grid>

					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<div className={classes.block} style={{backgroundColor : "green"}}>
							1
						</div>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Une case verte signifie que l'exercice a été reussi sans aucune aide.</p>
					</Grid>

					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<div className={classes.block} style={{backgroundColor : "orange"}}>
							1
						</div>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Une case verte signifie que l'exercice a été reussi avec au moins une aide.</p>
					</Grid>
				</Grid>

				<p>Si vous cliquez sur un exercice, vous obtenez son nom ainsi que l'historique des participations de l'usager à cet exercice.</p>
			</TutorialDialog>
		</>
	);
}

const AccordionCategory = ({usager, category, ateliers}) => {

	const [open, setOpen] = React.useState(true);

	const useStyles = makeStyles((theme) => ({
		accordionContainer : {
			width : "100%",
			"& .MuiAccordion-root" : {
				background : "linear-gradient(white, #FAFAFA)",
				color : theme.palette.text.main,
				fontSize : "100%",
				fontWeight : "400",
				margin : "0px",
				"& .MuiIconButton-root" : {
					color : theme.palette.text.main,
				}
			},
		}
	}));
	const classes = useStyles();

	return(
		<Accordion expanded={open} onChange={() => {setOpen(!open)}}>
			<AccordionSummary expandIcon={<ExpandMoreRounded />}>
				{category.name}
			</AccordionSummary>
			<AccordionDetails>
				<div className={classes.accordionContainer}>
					{ateliers.map((atelier, key) => (
						<AccordionAtelier
							key={key}
							usager={usager}
							atelier={atelier}
						/>
					))}
				</div>
			</AccordionDetails>
		</Accordion>
	);
}

const AccordionAtelier = ({usager, atelier}) => {

	const useStyles = makeStyles((theme) => ({
		exercicesContainer : {
			width : "100%",
			display :  "flex",
			flexWrap : "wrap",
			alignItems : "center",
		}
	}));
	const classes = useStyles();

	return(
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreRounded />}>
				Atelier {atelier.subId} : {atelier.name}
			</AccordionSummary>
			<AccordionDetails>
				<div className={classes.exercicesContainer}>
					{
						atelier.exercices.sort((a, b) => (a.id - b.id)).map((exercice, key) => (
							<ExerciceTab
								number={exercice.id}
								name={exercice.name}
								realisations={usager.exercicesResults ? (usager.exercicesResults.filter(ex => {
									return (ex.idAtelier === atelier.id && ex.idExercice === exercice.id)
								})) : []}
							/>
						))
					}
				</div>
			</AccordionDetails>
		</Accordion>
	);
}

const ExerciceTab = ({number, name, realisations}) => {

	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	const useStyles = makeStyles((theme) => ({
		block : {
			width : "75px",
			height : "30px",
			textAlign : "center",
			margin : "5px",
			backgroundColor : (realisations.filter(real => real.nbAides >= 0).length > 0 ? (realisations.filter(real => real.nbAides === 0).length > 0 ? "green" : "orange") : theme.palette.secondary.main),
			borderRadius : "5px",
			color : theme.palette.text.lighter,
			fontWeight : "700",
			cursor : "pointer"
		},
		titleTooltip : {
			width : "100%",
			textAlign : "center",
			fontWeight : "700",
		},
		textTooltip : {
			width : "100%",
			textAlign : "center",
			fontWeight : "900",
			fontSize : "125%",
			color : "white",
			margin : "0px",
			"& span" : {
				fontSize : "75%"
			}
		},
		textTooltipContainer : {
			padding : "5px",
			borderRadius : "5px",
			margin : "1px"
		}
	}));
	const classes = useStyles();

	return(
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<OpaqueTooltip
				disableFocusListener
				disableHoverListener
				disableTouchListener
				open={open}
				title={(
					<>
						<h6 className={classes.titleTooltip}>{name}</h6>
						<Grid container>
							{
								realisations.length <= 0 ? (
									<p className={classes.textTooltip} style={{padding: "5px", borderRadius : "5px", margin : "1px", backgroundColor : "red"}}>Pas de résultat</p>
								) : (realisations.map((real, key) => (
									<Grid item xs={12} key={key}>
										<Grid container className={classes.textTooltipContainer} style={{backgroundColor : (real.nbAides === 0 ? "green" : "orange")}}>
											<Grid item xs={9} style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
												<p className={classes.textTooltip}>
													{(new Date(real.date.seconds * 1000)).toLocaleDateString()}
												</p>
											</Grid>
											<Grid item xs={3}>
												<p className={classes.textTooltip}>
													{real.nbAides}<br/><span>aides</span>
												</p>
											</Grid>
										</Grid>
									</Grid>
								)))
							}
						</Grid>
					</>
				)}
			>
				<div className={classes.block} onClick={handleTooltipOpen}>
					{number}
				</div>
			</OpaqueTooltip>
		</ClickAwayListener>
	);
}

const OpaqueTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.secondary.light,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		padding : "15px",
		width : "150px"
	},
}))(Tooltip);