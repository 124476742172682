import React, {useContext, useEffect, useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid, IconButton, InputAdornment, TextField} from "@material-ui/core";

import firebaseConnector from "../api/firebase-connector"

import ActionContext from '../context/action.context'

import UsagerRow from "../components/Usagers/UsagerRow";
import DialogUsager from "../components/Usagers/DialogUsager";
import {
	CreateRounded,
	DeleteRounded,
	FilterNoneRounded,
	PlayArrowRounded,
	SearchRounded,
	VisibilityRounded
} from "@material-ui/icons";
import StringUtils from "../utils/StringUtils";
import TutorialDialog from "../components/TutorialDialog";

export default function DashboardUsagers() {
	const [usagersList, setUsagersList] = useState([])
	const [usagersListDisplay, setUsagersListDisplay] = useState([])
	const [filter, setFilter] = useState("")

	const [modalIsOpen, setModalIsOpen] = useState(false)

	let userId = firebaseConnector.auth().currentUser.uid;

	const useStyles = makeStyles((theme) => ({
		inputSearch: {
			backgroundColor: "white",
		},
		table: {
			color: theme.palette.text.main,
			marginTop : "30px",
			borderRadius : "25px",
			[theme.breakpoints.down('sm')]: {
				borderRadius : "25px 25px 0px 0px",
			},
			backgroundColor : "white",
			boxShadow : "1px 13px 35px 0px rgba(0,0,0,0.15)",
			padding : "0px 15px 0px 15px"
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		},
		buttonRowContainer : {
			padding : "0px 15px 0px 15px"
		},
		headerRow : {
			background : "transparent",
			borderBottom : theme.palette.primary.light + " 2px solid",
			padding : "15px"
		},
		tutorialInlineButton : {
			backgroundColor: theme.palette.primary.main,
			color: "white",
			display : "inline",
			margin : "10px 15px 10px 0px"
		},
		tutorialGridItem : {
			display : "flex",
			justifyContent : "center",
			alignItems : "center",
			marginBottom : "10px",
			"& p" : {
				margin : "0px",
			},
			borderBottom : "1px lightgrey solid",
			[theme.breakpoints.down("sm")] : {
				borderBottom : "none",
				"& p" : {
					width : "100%",
					paddingBottom : "10px",
					textAlign : "center",
					borderBottom : "1px lightgrey solid",
				},
			}
		}
	}));

	const classes = useStyles();

	useEffect(()=>{
		firebaseConnector.firestore().collection("Users_Data").doc(userId).collection("Usagers").orderBy("nom", "asc").onSnapshot((snapshot)=>{
			setUsagersList(snapshot.docs.map(doc=>doc.data()))
		})
	}, [])
	useEffect(()=>{
		const usagersFiltered= usagersList.filter(usager=>usager.nom.toLowerCase().includes(filter.toLowerCase()));
		setUsagersListDisplay(usagersFiltered)
	}, [usagersList, filter])

	const editUsager = async (usagerChanged) => {
		await firebaseConnector.firestore()
			.collection("Users_Data")
			.doc(userId)
			.collection('Usagers')
			.doc(usagerChanged.id)
			.set(usagerChanged, {merge : true})
	}
	const deleteUsager = async (usagerToDelete) => {
		await firebaseConnector.firestore()
			.collection("Users_Data")
			.doc(userId)
			.collection('Usagers')
			.doc(usagerToDelete.id)
			.delete()
	}
	const addUsager = async (usagerToAdd) => {
		usagerToAdd = {...usagerToAdd, ...{id: StringUtils.generateUuid()}};

		await firebaseConnector.firestore()
			.collection("Users_Data")
			.doc(userId)
			.collection('Usagers')
			.doc(usagerToAdd.id)
			.set(usagerToAdd)
	}

	return (
		<ActionContext.Provider value={{addUsager, editUsager, deleteUsager}}>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={classes.buttonRowContainer}>
						<Grid item xs={6} md={5}>
							<TextField
								onChange={(event)=>{setFilter(event.target.value)}}
								variant="outlined"
								value={filter}
								className={classes.inputSearch}
								id="input-search"
								fullWidth={true}
								placeholder="Rechercher un usager par nom ou prénom"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchRounded />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid xs={1} md={2}/>
						<Grid xs={5} md={5} style={{display : "flex", alignItems : "center", justifyContent : "flex-end"}}>
							<p style={{margin : "0px"}}>
								<Button
									color="primary"
									variant="contained"
									size="large"
									onClick={()=>{setModalIsOpen(true)}}
									style={{borderRadius : "25px"}}
								>
									Ajouter un usager
								</Button>
							</p>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container  className={classes.table}>
						<Grid item xs={12} style={{fontWeight : "bold"}}>
							<Grid container className={classes.headerRow}>
								<Grid item xs={3} md={3} className={classes.rowContainer}>
									<p className={classes.rowText}>
										Nom
									</p>
								</Grid>
								<Grid item xs={3} md={3} className={classes.rowContainer}>
									<p className={classes.rowText}>
										Prénom
									</p>
								</Grid>
								<Grid item xs={6} md={6} className={classes.rowContainer}>
									<p className={classes.rowTextCenter}>
										Actions
									</p>
								</Grid>
							</Grid>
						</Grid>
						{
							usagersListDisplay.length > 0 ? (
								usagersListDisplay.map((usager, index) => (
									<UsagerRow
										key={index}
										usager={usager}
									/>
								))
							) : (
								<Grid item xs={12}>
									<p
										style={{
											width : "100%",
											textAlign : "center",
											padding : "20px 0px"
										}}
									>
										Pas d'usagers 😕
									</p>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
			<DialogUsager
				handleClose={()=>{setModalIsOpen(false)}}
				isEdit={false}
				isOpen={modalIsOpen}
				usager={null}
			/>
			<TutorialDialog
				id={"usagers.dashboard"}
				subtitle={"Vos apprenants"}
			>
				Vous trouverez sur cette page la liste de vos usagers.<br/>
				En face de chaque usager vous trouverez quatre boutons :<br/>

				<Grid container>
					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<IconButton className={classes.tutorialInlineButton}>
							<VisibilityRounded />
						</IconButton>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Ce bouton permet de voir la progression de votre usager.</p>
					</Grid>

					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<IconButton className={classes.tutorialInlineButton}>
							<FilterNoneRounded />
						</IconButton>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Ce bouton permet de copier un lien spécifique à l'usager. Ce lien va lui permettre d'apprendre à travers les ateliers. La sauvegarde de sa progression est associée au lien, il aura simplement à recliquer sur le lien pour reprendre les ateliers là où il s'est arreté.</p>
					</Grid>

					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<IconButton className={classes.tutorialInlineButton}>
							<CreateRounded />
						</IconButton>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Ce bouton permet de faire l'atelier comme si vous etiez l'apprenant.</p>
					</Grid>

					<Grid item xs={12} md={2} className={classes.tutorialGridItem}>
						<IconButton className={classes.tutorialInlineButton}>
							<DeleteRounded />
						</IconButton>
					</Grid>
					<Grid item xs={12} md={10} className={classes.tutorialGridItem}>
						<p>Ce bouton permet de faire l'atelier comme si vous etiez l'apprenant.</p>
					</Grid>
				</Grid>
			</TutorialDialog>
		</ActionContext.Provider>
	);
}