import React, {useContext, useEffect, useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	TextField, Tooltip
} from "@material-ui/core";

import firebaseConnector from "../api/firebase-connector"

import ActionContext from '../context/action.context'

import AtelierRow from "../components/Ateliers/AtelierRow";
import DialogAtelier from "../components/Ateliers/DialogAtelier";
import {PlayArrowRounded, SearchRounded, VisibilityRounded} from "@material-ui/icons";
import StringUtils from "../utils/StringUtils";
import TutorialDialog from "../components/TutorialDialog";

export default function DashboardAteliers() {
	const [categoryList, setCategoryList] = useState([])
	const [ateliersList, setAteliersList] = useState([])
	const [ateliersListDisplay, setAteliersListDisplay] = useState([])
	const [filter, setFilter] = useState("")

	const [modalIsOpen, setModalIsOpen] = useState(false)

	let userId = firebaseConnector.auth().currentUser.uid;

	const useStyles = makeStyles((theme) => ({
		inputSearch: {
			backgroundColor: "white",
		},
		table: {
			color: theme.palette.text.main,
			marginTop : "30px",
			borderRadius : "25px",
			[theme.breakpoints.down('sm')]: {
				borderRadius : "25px 25px 0px 0px",
			},
			backgroundColor : "white",
			boxShadow : "1px 13px 35px 0px rgba(0,0,0,0.15)",
			padding : "0px 15px 0px 15px"
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		},
		buttonRowContainer : {
			padding : "0px 15px 0px 15px"
		},
		headerRow : {
			background : "transparent",
			borderBottom : theme.palette.primary.light + " 2px solid",
			padding : "15px"
		},
		tutorialInlineButton : {
			backgroundColor: theme.palette.primary.main,
			color: "white",
			display : "inline",
			margin : "10px 15px 10px 0px"
		}
	}));

	const classes = useStyles();

	useEffect(()=>{

		firebaseConnector.firestore().collection("Ateliers_Category").onSnapshot((snapshot => {
			setCategoryList(snapshot.docs.map(doc=>doc.data()))
			console.log(snapshot.docs.map(doc=>doc.data()))
		}))

		firebaseConnector.firestore().collection("Ateliers").orderBy("category", "asc").onSnapshot((snapshot)=>{

			let docArray = snapshot.docs.map(doc=>doc.data())

			docArray.sort((a,b) => {
				if(a.category === b.category){
					return a.subId - b.subId
				}else{
					return a.category - b.category
				}
			})

			setAteliersList(docArray)
		})
	}, [])
	useEffect(()=>{
		const ateliersFiltered= ateliersList.filter(atelier=>atelier.name.toLowerCase().includes(filter.toLowerCase()));
		setAteliersListDisplay(ateliersFiltered)
	}, [ateliersList, filter])

	return (
		<div>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={classes.buttonRowContainer}>
						<Grid item xs={12} md={12}>
							<TextField
								onChange={(event)=>{setFilter(event.target.value)}}
								variant="outlined"
								value={filter}
								className={classes.inputSearch}
								id="input-search"
								fullWidth={true}
								placeholder="Rechercher un atelier par nom ou catégorie"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchRounded />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container  className={classes.table}>
						<Grid item xs={12} style={{fontWeight : "bold"}}>
							<Grid container className={classes.headerRow}>
								<Grid item xs={3} md={4} className={classes.rowContainer}>
									<p className={classes.rowText}>
										Nom
									</p>
								</Grid>
								<Grid item xs={3} md={4} className={classes.rowContainer}>
									<p className={classes.rowText}>
										Catégorie
									</p>
								</Grid>
								<Grid item xs={6} md={4} className={classes.rowContainer}>
									<p className={classes.rowTextCenter}>
										Actions
									</p>
								</Grid>
							</Grid>
						</Grid>
						{
							ateliersListDisplay.length > 0 ? (
								ateliersListDisplay.map((atelier, index) => (
									<AtelierRow
										key={index}
										atelier={atelier}
										categories={categoryList}
									/>
								))
							) : (
								<Grid item xs={12}>
									<p
										style={{
											width : "100%",
											textAlign : "center",
											padding : "20px 0px"
										}}
									>
										Pas d'ateliers 😕
									</p>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
			<TutorialDialog
				id={"atelier.dashboard"}
				subtitle={"La liste des ateliers"}
			>
				Vous trouverez sur cette page la liste de tous les ateliers.<br/>
				En face de chaque atelier vous trouverez deux boutons :<br/>

				<IconButton className={classes.tutorialInlineButton}>
					<VisibilityRounded />
				</IconButton>
				Ce bouton permet de voir le detail des exercices de l'atelier.<br/>

				<IconButton className={classes.tutorialInlineButton}>
					<PlayArrowRounded />
				</IconButton>
				Ce bouton permet de faire l'atelier comme si vous etiez l'apprenant.<br/>
			</TutorialDialog>
		</div>
	);
}