import Swal from 'sweetalert2';

class Alert {
    static error(error){
        let errorMessage = "";
        if(error.response && error.response.data.error){
            errorMessage = error.response.data.error;
        } else if(error.response) {
            errorMessage = error.response.data.sqlMessage;
        } else {
            errorMessage = error;
        }

        Swal.fire({
            icon: "error",
            title: "Erreur",
            text: errorMessage,
            showCancelButton: false
        });
    }

    static warningDelete(){
        const swalOptions = {
            title: 'Êtes-vous sûr ?',
            text: 'Vous ne pourrez pas revenir en arrière par la suite',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: "Annuler"
        };

        return new Promise((resolve => {
            Swal.fire(swalOptions).then((result) => {
                if (result.value) {
                    resolve()
                }
            })
        }))
    }
}

export default Alert